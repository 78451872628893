import React from "react";
import loadable from "@loadable/component";
import { graphql } from "gatsby";

const Layout = loadable(() => import("@common/Layout/Layout"));
const ContactUs = loadable(() => import("@components/ContactUs/ContactUs"));
const SEO = loadable(() => import("@components/Common/SEO/SEO"));
const ContactUsPage = ({ path, data }) => (
    <>
        <SEO
            title="Hey! We're Always Ready to Chat About Your Business!"
            titleTemplate="Impressit"
            SEOData={data.allStrapiSeoDescriptions?.nodes}
            description="Contact Impressit and get your prototyping, custom software development, and other tech support at a unique price-to-quality ratio"
            path={path}
        />
        <Layout path={path}>
            <ContactUs />
        </Layout>
    </>
);

export default ContactUsPage;

export const contactUsPageQuery = graphql`
    query aboutPageSEODataQuery {
        allStrapiSeoDescriptions {
            nodes {
                Title
                MetaTitle
                PageUrl
            }
        }
    }
`;
